import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getSuggestions, PageName } from '@whitbread-eos/api';
import { ErrorBoundary } from '@whitbread-eos/atoms';
import { SEO as Seo } from '@whitbread-eos/molecules';
import {
  getSearchParams,
  getSearchResultsURLQuery,
  PISearchContainer as Search,
  SearchResultsPIVariant,
} from '@whitbread-eos/organisms';
import { useCustomLocale, getDefaultRooms } from '@whitbread-eos/utils';
import { NextRouter } from 'next/router';
import { useEffect, useState } from 'react';

interface Props {
  router: NextRouter;
  queryClient: QueryClient;
}

export default function SearchPagePI({ router, queryClient }: Readonly<Props>) {
  const [showNoHotelsWarning, setShowNoHotelsWarning] = useState(false);
  const multiSearchParams = getSearchParams(router.query);
  const { placeId, coordinates, location, corpId } = multiSearchParams;
  const { language, country } = useCustomLocale();

  useEffect(() => {
    if (!placeId && !coordinates) {
      getSuggestions(location)
        .then((response) => {
          const newQuery = getSearchResultsURLQuery(multiSearchParams, response, router.query);
          return router.push(
            {
              pathname: `${country}/${language}${router.pathname}.html`,
              query: newQuery,
              search: undefined,
            },
            undefined,
            { shallow: true }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [placeId, coordinates, location]);

  const defaultRooms = getDefaultRooms(router.query);

  const {
    arrivalDay: ARRdd,
    arrivalMonth: ARRmm,
    arrivalYear: ARRyyyy,
    numberOfNights: NIGHTS,
    rooms: ROOMS,
  } = multiSearchParams;

  const noHotelsWarningHandle = (value: boolean) => {
    setShowNoHotelsWarning(value);
  };

  const isMetaSearch = 'WEB' !== (router.query?.BOOKINGCHANNEL as string);

  return (
    <QueryClientProvider client={queryClient}>
      <Flex {...containerStyles}>
        <Seo page={PageName.SRP} displayMeta />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ErrorBoundary noContentBoundary={true}>
          <Box {...searchContainerStyles}>
            <Search
              queryClient={queryClient}
              isSummaryActive={!isMetaSearch}
              defaultLocation={location}
              defaultRooms={defaultRooms}
              ARRdd={Number(ARRdd)}
              ARRmm={Number(ARRmm)}
              ARRyyyy={Number(ARRyyyy)}
              NIGHTS={Number(NIGHTS)}
              ROOMS={Number(ROOMS)}
              CORPID={corpId}
              showNoHotelsWarning={showNoHotelsWarning}
            />
          </Box>
        </ErrorBoundary>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ErrorBoundary>
          <SearchResultsPIVariant
            queryClient={queryClient}
            multiSearchParams={multiSearchParams}
            onNoHotelsWarning={noHotelsWarningHandle}
          />
        </ErrorBoundary>
      </Flex>
    </QueryClientProvider>
  );
}

const searchContainerStyles = {
  maxWidth: {
    mobile: '100%',
    lg: 'var(--chakra-space-breakpoint-lg)',
    xl: 'var(--chakra-space-breakpoint-xl)',
  },
  px: {
    mobile: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    lg: '1.75rem',
    xl: '4.125rem',
  },
  paddingTop: 'var(--chakra-space-sm)',
  mx: 'auto',
  width: '100%',
};
const containerStyles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
} as FlexProps;
