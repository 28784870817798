import { dehydrate, QueryClient } from '@tanstack/react-query';
import {
  GET_SEARCH_RULES_QUERY,
  GET_SEO_INFORMATION,
  GET_STATIC_CONTENT,
  MULTI_HOTEL_AVAILABILITIES_QUERY_PI_V2,
  PageName,
  SEARCH_INFORMATION_RESULTS,
  SITE_LEISURE,
} from '@whitbread-eos/api';
import { APP_VARIANT, getSearchParams } from '@whitbread-eos/organisms';
import {
  getDistanceUnitBasedOnLocale,
  getGQLClient,
  graphQLRequest,
  QueriesLogger,
  isDateValid,
} from '@whitbread-eos/utils';
import add from 'date-fns/add';
import format from 'date-fns/format';
import { GetServerSidePropsContext } from 'next';
import { ParsedUrlQuery } from 'querystring';

interface Props extends GetServerSidePropsContext {
  queryClient: QueryClient;
  language: string;
  country: string;
  query: ParsedUrlQuery;
}

const createSearchResultsPiDataLoader = async ({
  queryClient,
  language,
  country,
  query,
  req,
  res,
}: Props) => {
  const client = getGQLClient();

  const { fetchQuery, prefetchQuery, logQueries } = new QueriesLogger(
    queryClient,
    req,
    res,
    query,
    'PI | SRP | Search Results Page'
  );

  const multiSearchParams = getSearchParams(query);

  const isValidDate = isDateValid(
    Number(multiSearchParams.arrivalDay),
    Number(multiSearchParams.arrivalMonth),
    Number(multiSearchParams.arrivalYear)
  );
  const startDate = isValidDate
    ? new Date(
        Number(multiSearchParams.arrivalYear),
        Number(multiSearchParams.arrivalMonth && multiSearchParams.arrivalMonth - 1),
        Number(multiSearchParams.arrivalDay)
      )
    : new Date();

  const place = {
    location: multiSearchParams.placeId ?? multiSearchParams.coordinates,
    locationFormat: multiSearchParams.placeId ? 'PLACEID' : 'LATLONG',
    radius: language === 'en' ? 30 : 50, // should retrieve radius from BE - new labels query
    radiusUnit: getDistanceUnitBasedOnLocale(language),
  };

  const endDate = add(startDate, { days: Number(multiSearchParams.numberOfNights) });

  const paramsForQuery = {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    rooms: multiSearchParams.rooms,
    place,
    oldWorldChannel: multiSearchParams.bookingChannel,
    channel: APP_VARIANT.PI,
    subChannel: multiSearchParams.bookingChannel,
    page: 1,
    initialPageSize: '40',
    lazyLoadPageSize: '10',
    country,
    language,
    sort: multiSearchParams.sort,
    filters: multiSearchParams.filters,
    ratePlanCodes: multiSearchParams?.cellCodes ?? [],
  };

  const searchInformationQuery = prefetchQuery(
    ['searchInformation', paramsForQuery.country, paramsForQuery.language],
    () =>
      graphQLRequest(
        SEARCH_INFORMATION_RESULTS,
        {
          country: paramsForQuery.country,
          language: paramsForQuery.language,
        },
        undefined,
        undefined,
        client
      )
  );

  const hotelAvailabilitiesQuery = prefetchQuery(
    [
      'hotelAvailabilitiesV2',
      paramsForQuery.startDate,
      paramsForQuery.endDate,
      paramsForQuery.rooms,
      paramsForQuery.place,
      paramsForQuery.oldWorldChannel,
      paramsForQuery.channel,
      paramsForQuery.subChannel,
      paramsForQuery.page,
      paramsForQuery.initialPageSize,
      paramsForQuery.lazyLoadPageSize,
      paramsForQuery.country,
      paramsForQuery.language,
      paramsForQuery.sort,
      paramsForQuery.filters,
      paramsForQuery.ratePlanCodes,
    ],

    () =>
      graphQLRequest(
        MULTI_HOTEL_AVAILABILITIES_QUERY_PI_V2,
        { ...paramsForQuery },
        undefined,
        undefined,
        client
      )
  );

  const seoQuery = prefetchQuery(
    ['seoInformation', paramsForQuery.language, paramsForQuery.country, PageName.SRP],
    () =>
      graphQLRequest(
        GET_SEO_INFORMATION,
        {
          language: paramsForQuery.language,
          country: paramsForQuery.country,
          page: PageName.SRP,
        },
        undefined,
        undefined,
        client
      )
  );

  const getStaticContentQuery = fetchQuery(['GetStaticContent', language, country], () =>
    graphQLRequest(
      GET_STATIC_CONTENT,
      {
        language,
        country,
        site: SITE_LEISURE,
        businessBooker: false,
      },
      undefined,
      undefined,
      client
    )
  );

  const searchRulesQuery = prefetchQuery(['getSearchRules', 'PI'], () =>
    graphQLRequest(
      GET_SEARCH_RULES_QUERY,
      {
        channel: 'PI',
      },
      undefined,
      undefined,
      client
    )
  );

  await Promise.all([
    getStaticContentQuery,
    hotelAvailabilitiesQuery,
    searchInformationQuery,
    searchRulesQuery,
    seoQuery,
  ]);

  logQueries(performance.now());

  return {
    dehydratedState: dehydrate(queryClient),
  };
};

export default createSearchResultsPiDataLoader;
