import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary } from '@whitbread-eos/atoms';
import {
  MAX_CLOSE_COUNT,
  PUSH_REQUEST_CLOSED_COUNT,
  PUSH_REQUEST_CLOSED_TIMESTAMP,
} from '@whitbread-eos/molecules';
import {
  getI18nLabels,
  getServerSideCustomLocale,
  getUnleashToggles,
  useFeatureToggle,
} from '@whitbread-eos/utils';
import Cookies from 'cookies';
import { GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';

import { DefaultLayout } from '~components';
import useServiceWorker from '~hooks/use-service-worker';
import { createSearchResultsPiDataLoader, SearchPagePI } from '~page-helper/search/index';
import { PAGE } from '~utils/pi-all-pages-constants';

interface Props {
  featureToggles: { [key: string]: boolean };
  pushRequestClosedCountCookie: number;
  pushRequestClosedTimestampCookie: string | null;
}

const ConsentNotificationModal = dynamic(
  async () => {
    const { ConsentNotificationModal } = await import('@whitbread-eos/molecules');
    return { default: ConsentNotificationModal };
  },
  {
    ssr: false,
  }
);

export default function SearchPage(props: Readonly<Props>) {
  const { featureToggles, pushRequestClosedCountCookie, pushRequestClosedTimestampCookie } = props;
  useFeatureToggle(featureToggles);
  const isNotificationPermissionEnabledForPI = featureToggles.release_pi_web_push_notifications;
  const { handleNotificationPermission } = useServiceWorker(isNotificationPermissionEnabledForPI);

  const checkConsentNotificationAvailability =
    typeof window !== 'undefined' &&
    'Notification' in window &&
    Notification.permission === 'default' &&
    pushRequestClosedCountCookie < MAX_CLOSE_COUNT &&
    !pushRequestClosedTimestampCookie;

  return (
    <>
      <SearchPagePI router={useRouter()} queryClient={useQueryClient()} />
      {isNotificationPermissionEnabledForPI && checkConsentNotificationAvailability && (
        <ConsentNotificationModal handleNotificationPermission={handleNotificationPermission} />
      )}
    </>
  );
}

SearchPage.getLayout = function getLayout(page: ReactElement) {
  const containerStyles = {
    mx: 0,
    px: 0,
    maxWidth: '100% !important',
    height: '100%',
  };

  const mainStyles = {
    mb: '-0.5rem !important',
  };

  return (
    <>
      <DefaultLayout
        showPromoCode={true}
        showFooter={false}
        containerStyles={containerStyles}
        mainStyles={mainStyles}
      >
        <ErrorBoundary>{page}</ErrorBoundary>
      </DefaultLayout>
    </>
  );
};

export async function getServerSideProps({ locale = 'gb', ...props }: GetServerSidePropsContext) {
  const cookies = new Cookies(props.req, props.res);
  const queryClient = new QueryClient();

  const { language, country } = getServerSideCustomLocale(locale);

  const labels = await getI18nLabels({
    language,
    queryClient,
  });

  const featureToggles: { [key: string]: boolean } = await getUnleashToggles(
    props,
    PAGE.HDP.featureToggles.appPage,
    PAGE.HDP.featureToggles.flagsWithFallback
  );

  const pushRequestClosedCountCookie = Number(cookies.get(PUSH_REQUEST_CLOSED_COUNT) ?? 0);
  const pushRequestClosedTimestampCookie = cookies.get(PUSH_REQUEST_CLOSED_TIMESTAMP) ?? null;

  const loadedData = await createSearchResultsPiDataLoader({
    queryClient,
    language,
    country,
    ...props,
  });

  return {
    props: {
      pushRequestClosedCountCookie,
      pushRequestClosedTimestampCookie,
      featureToggles,
      ...loadedData,
      ...labels,
    },
  };
}
